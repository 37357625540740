import { filterAsyncRoutes } from '@/untils/asyncRouter'
import { asyncRoutes } from '@/router'

export default {
  state: {
    routes: []
  },
  getters: {
  },
  mutations: {
    SET_ROUTES: (state, routes) => {
      state.routes = routes
    }
  },
  actions: {
    generateRoutes ({ commit, rootState }) {
      return new Promise(resolve => {
        const { keys, role } = rootState.user
        const accessedRoutes = filterAsyncRoutes(asyncRoutes[role] || [], keys)
        commit('SET_ROUTES', accessedRoutes)
        resolve(accessedRoutes)
      })
    }
  }
}

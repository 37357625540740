import BackLayout from '@/views/back/LayoutView'
const HZDJ = [
  {
    path: '/memberindex',
    component: BackLayout,
    meta: {
      keys: ['memberindex']
    },
    children: [
      {
        path: '',
        meta: {
          authorities: 'login',
          title: '后台首页',
          icon: 'icon-home',
          keys: ['memberindex']
        },
        component: () => import(/* webpackChunkName: "memberindex" */ '@/views/back/dashboard/hzdj/IndexView')
      }
    ]
  },
  {
    path: '/viraccountset',
    component: BackLayout,
    meta: {
      title: '个人账户设置',
      icon: 'icon-Union-5',
      keys: ['viraccountset']
    },
    alwaysShow: true,
    children: [
      {
        path: 'accbaseinfo',
        meta: {
          authorities: 'login',
          title: '个人基本信息',
          keys: ['accbaseinfo']
        },
        component: () => import(/* webpackChunkName: "viraccountset-accbaseinfo" */ '@/views/person/basexx/IndexView')
      },
      {
        path: 'cardxx',
        meta: {
          title: '身份基本信息',
          activeMenu: '/viraccountset/accbaseinfo',
          keys: ['accbaseinfo'],
          authorities: 'login'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "viraccountset-cardxx" */ '@/views/person/basexx/IDCardView')
      },
      {
        path: 'mobmgr',
        meta: {
          title: '修改手机号',
          activeMenu: '/viraccountset/accbaseinfo',
          keys: ['accbaseinfo'],
          authorities: 'login'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "viraccountset-mobmgr" */ '@/views/person/basexx/phone/IndexView')
      },
      {
        path: 'myrzmgr',
        meta: {
          authorities: 'login',
          title: '我的认证',
          keys: ['myrzmgr']
        },
        component: () => import(/* webpackChunkName: "viraccountset-myrzmgr" */ '@/views/person/certification/ApplyView')
      },
      {
        path: 'person',
        meta: {
          title: '实名认证',
          activeMenu: '/viraccountset/myrzmgr',
          keys: ['myrzmgr'],
          authorities: 'login'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "viraccountset-person" */ '@/views/person/certification/person/IndexView')
      },
      {
        path: 'gdrzpersonchk',
        meta: {
          title: '货主帝家认证',
          authorities: 'login',
          activeMenu: '/viraccountset/myrzmgr',
          keys: ['myrzmgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "viraccountset-gdrzpersonchk" */ '@/views/person/certification/hzdj/user/ChkView')
      },
      {
        path: 'gdrzpersonunchk',
        meta: {
          title: '货主帝家认证',
          authorities: 'login',
          activeMenu: '/viraccountset/myrzmgr',
          keys: ['myrzmgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "viraccountset-gdrzpersonunchk" */ '@/views/person/certification/hzdj/user/UnChkView')
      },
      {
        path: 'gdrzcompchk',
        meta: {
          title: '货主帝家认证',
          authorities: 'login',
          activeMenu: '/viraccountset/myrzmgr',
          keys: ['myrzmgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "viraccountset-gdrzcompchk" */ '@/views/person/certification/hzdj/comp/ChkView')
      },
      {
        path: 'gdrz',
        redirect: 'gdrz/comp',
        meta: {
          title: '货主帝家认证',
          authorities: 'login',
          activeMenu: '/viraccountset/myrzmgr',
          keys: ['myrzmgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "viraccountset-gdrz" */ '@/views/person/certification/hzdj/IndexView'),
        children: [
          {
            path: 'comp',
            meta: {
              title: '企业货主认证',
              authorities: 'login',
              activeMenu: '/viraccountset/myrzmgr',
              keys: ['myrzmgr']
            },
            component: () => import(/* webpackChunkName: "viraccountset-gdrzcomp" */ '@/views/person/certification/hzdj/comp/IndexView')
          },
          {
            path: 'person',
            meta: {
              title: '个人货主认证',
              authorities: 'login',
              activeMenu: '/viraccountset/myrzmgr',
              keys: ['myrzmgr']
            },
            component: () => import(/* webpackChunkName: "viraccountset-gdrzperson" */ '@/views/person/certification/hzdj/user/IndexView')
          }
        ]
      },
      {
        path: 'gdrzcompunchk',
        meta: {
          title: '货主帝家认证',
          authorities: 'login',
          activeMenu: '/viraccountset/myrzmgr',
          keys: ['myrzmgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "viraccountset-gdrzcompunchk" */ '@/views/person/certification/hzdj/comp/UnChkView')
      },
      {
        path: 'mmtwallet',
        meta: {
          authorities: 'login',
          title: '默默账簿',
          keys: ['mmtwallet']
        },
        component: () => import(/* webpackChunkName: "viraccountset-mmtwallet" */ '@/views/person/wallet/hzdj/IndexView')
      },
      {
        path: 'creditstar',
        meta: {
          authorities: 'login',
          title: '信用星级',
          keys: ['creditstar']
        },
        component: () => import(/* webpackChunkName: "viraccountset-creditstar" */ '@/views/person/creditstar/hzdj/IndexView')
      },
      {
        path: 'modpassword',
        meta: {
          authorities: 'login',
          title: '修改密码',
          keys: ['modpassword']
        },
        component: () => import(/* webpackChunkName: "viraccountset-modpassword" */ '@/views/person/password/IndexView')
      }
    ]
  },
  {
    path: '/gdsrcmgr',
    component: BackLayout,
    meta: {
      keys: ['gdsrcmgr'],
      title: '货源管理中心'
    },
    children: [
      {
        path: '',
        name: 'GoodListMgr',
        meta: {
          authorities: 'login',
          title: '货源管理中心',
          breadcrumb: false,
          icon: 'icon-Union1',
          keys: ['gdsrcmgr']
        },
        component: () => import(/* webpackChunkName: "gdsrcmgr" */ '@/views/back/goods/list/IndexView')
      },
      {
        path: 'add',
        meta: {
          authorities: 'login',
          title: '发布货源',
          keys: ['gdsrcmgr'],
          activeMenu: '/gdsrcmgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "gdsrcmgr-add" */ '@/views/back/goods/detail/IndexView')
      },
      {
        path: 'edit',
        meta: {
          authorities: 'login',
          title: '编辑货源',
          keys: ['gdsrcmgr'],
          activeMenu: '/gdsrcmgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "gdsrcmgr-edit" */ '@/views/back/goods/detail/IndexView')
      },
      {
        path: 'agree',
        meta: {
          authorities: 'login',
          title: '货物委托协议',
          keys: ['gdsrcmgr'],
          activeMenu: '/gdsrcmgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "gdsrcmgr-agree" */ '@/views/back/goods/agree/IndexView')
      },
      {
        path: 'ckagree',
        meta: {
          title: '货物委托协议',
          authorities: 'login',
          activeMenu: '/gdsrcmgr',
          keys: ['gdsrcmgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "virordermgr-ckagree" */ '@/views/back/order/agree/IndexView')
      },
      {
        path: 'result',
        meta: {
          authorities: 'login',
          title: '货物委托协议',
          keys: ['gdsrcmgr'],
          activeMenu: '/gdsrcmgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "gdsrcmgr-result" */ '@/views/back/goods/agree/result/IndexView')
      }
    ]
  },
  {
    path: '/collect',
    component: BackLayout,
    meta: {
      title: '收藏管理中心'
    },
    hidden: true,
    children: [
      {
        path: 'tklist',
        meta: {
          authorities: 'login',
          title: '车源收藏',
          activeMenu: '/memberindex'
        },
        component: () => import(/* webpackChunkName: "collect-tklist" */ '@/views/back/collect/tklist/IndexView')
      },
      {
        path: 'gdlist',
        meta: {
          authorities: 'login',
          title: '货源收藏',
          activeMenu: '/memberindex'
        },
        component: () => import(/* webpackChunkName: "collect-gdlist" */ '@/views/back/collect/gdlist/IndexView')
      }
    ]
  },
  {
    path: '/virordermgr',
    component: BackLayout,
    redirect: '/virordermgr/wtordermgr',
    meta: {
      keys: ['virordermgr'],
      title: '订单管理中心',
      icon: 'icon-Union-2'
    },
    alwaysShow: true,
    children: [
      {
        path: 'wtordermgr',
        meta: {
          authorities: 'login',
          title: '我的订单',
          keys: ['wtordermgr']
        },
        component: () => import(/* webpackChunkName: "virordermgr-wtordermgr" */ '@/views/back/wtorder/list/IndexView')
      },
      {
        path: 'detail',
        meta: {
          title: '查看详情',
          authorities: 'login',
          keys: ['wtordermgr'],
          activeMenu: '/virordermgr/wtordermgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "virordermgr-detail" */ '@/views/back/wtorder/detail/IndexView')
      },
      {
        path: 'myorders',
        name: 'HzdjMyOrderMgr',
        meta: {
          authorities: 'login',
          title: '我的运单',
          keys: ['myorders']
        },
        component: () => import(/* webpackChunkName: "virordermgr-myorders" */ '@/views/back/myorders/hzdj/list/IndexView')
      },
      {
        path: 'mydetail',
        meta: {
          authorities: 'login',
          title: '查看详情',
          keys: ['myorders'],
          activeMenu: '/virordermgr/myorders'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "virordermgr-mydetail" */ '@/views/back/myorders/detail/IndexView')
      }
    ]
  },
  {
    path: '/enquirymgr',
    component: BackLayout,
    meta: {
      keys: ['queryjgmgr'],
      title: '询价管理中心'
    },
    children: [
      {
        path: '',
        name: 'EnquiryListMgr',
        meta: {
          authorities: 'login',
          title: '询价管理中心',
          breadcrumb: false,
          icon: 'icon-Union1',
          keys: ['queryjgmgr']
        },
        component: () => import(/* webpackChunkName: "gdsrcmgr" */ '@/views/back/enquiry/list/IndexView')
      },
      {
        path: 'add',
        meta: {
          authorities: 'login',
          title: '发布询价',
          keys: ['queryjgmgr'],
          activeMenu: '/enquirymgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "gdsrcmgr-add" */ '@/views/back/enquiry/detail/IndexView')
      }
    ]
  },
  {
    path: '/virjsmgr',
    component: BackLayout,
    meta: {
      keys: ['virjsmgr'],
      title: '结算管理中心',
      icon: 'icon-jiesuanguanli'
    },
    alwaysShow: true,
    children: [
      // {
      //   path: 'reconciliation',
      //   name: 'ReconciliationComponent',
      //   meta: {
      //     authorities: 'login',
      //     title: '对账管理',
      //     keys: ['dzordermgr']
      //   },
      //   component: () => import(/* webpackChunkName: "reconciliation" */ '@/views/back/reconciliation/hzdj/list/IndexView')
      // },
      {
        path: 'recon-order-list',
        name: 'ReconOrderList',
        meta: {
          authorities: 'login',
          title: '订单台账',
          keys: ['dzordermgr']
        },
        component: () => import(/* webpackChunkName: "recon-order-list" */ '@/views/back/reconciliation/new/hzdj/orderlist/IndexView')
      },
      {
        path: 'recon-order-list-detail',
        meta: {
          authorities: 'login',
          title: '订单台账详情',
          keys: ['dzordermgr'],
          activeMenu: '/virjsmgr/recon-order-list'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "recon-new-detail" */ '@/views/back/reconciliation/new/hzdj/orderlist/DetailView')
      },
      {
        path: 'reconciliation-new',
        name: 'ReconciliationNew',
        meta: {
          authorities: 'login',
          title: '对账管理',
          keys: ['dzordermgr']
        },
        component: () => import(/* webpackChunkName: "reconciliation-new" */ '@/views/back/reconciliation/new/hzdj/list/IndexView')
      },
      {
        path: 'recon-new-detail',
        meta: {
          authorities: 'login',
          title: '对账详情',
          keys: ['dzordermgr'],
          activeMenu: '/virjsmgr/reconciliation-new'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "recon-new-detail" */ '@/views/back/reconciliation/new/hzdj/list/DetailView')
      },
      {
        path: 'reconciliation-detail',
        meta: {
          authorities: 'login',
          title: '对账详情',
          keys: ['dzordermgr'],
          activeMenu: '/virjsmgr/reconciliation'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "reconciliation-detail" */ '@/views/back/reconciliation/hzdj/detail/IndexView')
      },
      {
        path: 'reconciliation-detail2',
        meta: {
          authorities: 'login',
          title: '对账详情',
          keys: ['dzordermgr'],
          activeMenu: '/virjsmgr/reconciliation'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "reconciliation-detail2" */ '@/views/back/reconciliation/hzdj/detail/DetailView')
      },
      {
        path: 'yzffkapp',
        name: 'PayApplyOrderMgr',
        meta: {
          authorities: 'login',
          title: '付款申请',
          keys: ['yzffkapp']
        },
        component: () => import(/* webpackChunkName: "ordersignmgr" */ '@/views/back/jsapply/payapply/IndexView')
      },
      {
        path: 'ordersignmgr',
        meta: {
          authorities: 'login',
          title: '签收管理',
          keys: ['ordersignmgr']
        },
        component: () => import(/* webpackChunkName: "ordersignmgr" */ '@/views/back/ordersign/hzdj/IndexView')
      },
      {
        path: 'ordersigndetail',
        meta: {
          authorities: 'login',
          title: '查看详情',
          keys: ['ordersignmgr'],
          activeMenu: '/virjsmgr/ordersignmgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "ordersigndetail" */ '@/views/back/ordersign/hzdj/detail/IndexView')
      },
      {
        path: 'yfjsmgr',
        meta: {
          authorities: 'login',
          title: '运费结算',
          keys: ['yfjsmgr']
        },
        component: () => import(/* webpackChunkName: "yfjsmgr" */ '@/views/back/yfjs/hzdj/list/IndexView')
      },
      {
        path: 'yfjsapply',
        meta: {
          authorities: 'login',
          title: '发起运费结算申请',
          keys: ['yfjsmgr'],
          activeMenu: '/virjsmgr/yfjsmgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "yfjsapply" */ '@/views/back/yfjs/hzdj/apply/IndexView')
      },
      {
        path: 'yfjsreapply',
        meta: {
          authorities: 'login',
          title: '重新提交申请',
          keys: ['yfjsmgr'],
          activeMenu: '/virjsmgr/yfjsmgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "yfjsreapply" */ '@/views/back/yfjs/hzdj/reapply/IndexView')
      },
      {
        path: 'yfjsdetail',
        meta: {
          authorities: 'login',
          title: '查看详情',
          keys: ['yfjsmgr'],
          activeMenu: '/virjsmgr/yfjsmgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "yfjsdetail" */ '@/views/back/yfjs/hzdj/detail/IndexView')
      }
    ]
  },
  {
    path: '/virwhmgr',
    component: BackLayout,
    meta: {
      keys: ['virwhmgr'],
      title: '仓储管理中心',
      icon: 'icon-cangkuguanli'
    },
    alwaysShow: true,
    children: [
      {
        path: 'invenapp',
        meta: {
          authorities: 'login',
          title: '盘点申请',
          keys: ['invenapp'],
          iframeSrc: '/plugins/whserv/project2/page/check-apply-index.html'
        },
        component: () => import(/* webpackChunkName: "invenapp" */ '@/views/back/warehouse/iframe/IndexView')
      },
      {
        path: 'rknotices',
        meta: {
          authorities: 'login',
          title: '入库通知',
          keys: ['rknotices'],
          iframeSrc: '/plugins/whserv/project2/page/storage-enter-notice.html'
        },
        component: () => import(/* webpackChunkName: "rknotices" */ '@/views/back/warehouse/iframe/IndexView')
      },
      {
        path: 'cknotices',
        meta: {
          authorities: 'login',
          title: '出库通知',
          keys: ['cknotices'],
          iframeSrc: '/plugins/whserv/project2/page/storage-outer-notice.html'
        },
        component: () => import(/* webpackChunkName: "cknotices" */ '@/views/back/warehouse/iframe/IndexView')
      },
      {
        path: 'realinven',
        meta: {
          authorities: 'login',
          title: '实时库存',
          keys: ['realinven'],
          iframeSrc: '/plugins/whserv/project1/page/ssStock4hz/index.html'
        },
        component: () => import(/* webpackChunkName: "realinven" */ '@/views/back/warehouse/iframe/IndexView')
      },
      {
        path: 'invenaccount',
        meta: {
          authorities: 'login',
          title: '库存台帐',
          keys: ['invenaccount'],
          iframeSrc: '/plugins/whserv/project1/page/stockTable4hz/index.html'
        },
        component: () => import(/* webpackChunkName: "invenaccount" */ '@/views/back/warehouse/iframe/IndexView')
      }
    ]
  },
  {
    path: '/virtruckmgr',
    component: BackLayout,
    meta: {
      keys: ['virtruckmgr'],
      title: '车辆管理中心',
      icon: 'icon-cheliangguanli'
    },
    alwaysShow: true,
    children: [
      {
        path: 'realpos',
        meta: {
          authorities: 'login',
          title: '最新位置查询',
          keys: ['realpos']
        },
        component: () => import(/* webpackChunkName: "realpos" */ '@/views/back/realpos/IndexView')
      },
      {
        path: 'histrack',
        meta: {
          authorities: 'login',
          title: '历史轨迹查询',
          keys: ['histrack']
        },
        component: () => import(/* webpackChunkName: "histrack" */ '@/views/back/histrack/IndexView')
      }
    ]
  },
  {
    path: '/zxtsmgr',
    component: BackLayout,
    meta: {
      keys: ['zxtsmgr'],
      title: '咨询投诉中心'
    },
    children: [
      {
        path: '',
        meta: {
          authorities: 'login',
          title: '咨询投诉中心',
          breadcrumb: false,
          icon: 'icon-zixun1',
          keys: ['zxtsmgr']
        },
        component: () => import(/* webpackChunkName: "zxtsmgr" */ '@/views/back/zxts/list/IndexView')
      },
      {
        path: 'add',
        meta: {
          authorities: 'login',
          title: '发布咨询投诉',
          keys: ['zxtsmgr'],
          activeMenu: '/zxtsmgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "zxtsmgr-add" */ '@/views/back/zxts/IndexView')
      }
    ]
  },
  {
    path: '/virsysmgr',
    component: BackLayout,
    meta: {
      keys: ['virsysmgr'],
      title: '系统管理中心',
      icon: 'icon-Union'
    },
    alwaysShow: true,
    children: [
      {
        path: 'deptmgr',
        meta: {
          authorities: 'login',
          title: '部门管理',
          keys: ['deptmgr']
        },
        component: () => import(/* webpackChunkName: "virsysmgr-deptmgr" */ '@/views/back/department/IndexView')
      },
      {
        path: 'staffmgr',
        meta: {
          authorities: 'login',
          title: '员工账号管理',
          keys: ['staffmgr']
        },
        component: () => import(/* webpackChunkName: "virsysmgr-staffmgr" */ '@/views/back/compuser/IndexView')
      },
      {
        path: 'flowmgr',
        meta: {
          authorities: 'login',
          title: '流程管理',
          keys: ['flowmgr']
        },
        component: () => import(/* webpackChunkName: "virsysmgr-flowmgr" */ '@/views/back/flow/IndexView')
      },
      {
        path: 'newmgr',
        meta: {
          authorities: 'login',
          title: '资讯管理',
          keys: ['newmgr']
        },
        component: () => import(/* webpackChunkName: "virsysmgr-newmgr" */ '@/views/back/news/list/IndexView')
      },
      {
        path: 'pubnews',
        meta: {
          title: '发布资讯',
          authorities: 'login',
          keys: ['newmgr'],
          activeMenu: '/virsysmgr/newmgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "pubnews" */ '@/views/back/news/publish/IndexView')
      },
      {
        path: 'editnews',
        meta: {
          title: '编辑资讯',
          authorities: 'login',
          keys: ['newmgr'],
          activeMenu: '/virsysmgr/newmgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "editnews" */ '@/views/back/news/detail/IndexView')
      }
    ]
  },
  {
    path: '/regyjunionai',
    component: BackLayout,
    meta: {
      keys: ['regyjunionai'],
      title: '跨区域物流联动AI'
    },
    children: [
      {
        path: '',
        meta: {
          authorities: 'login',
          title: '跨区域物流联动AI',
          breadcrumb: false,
          icon: 'icon-quyuyunjia',
          keys: ['regyjunionai']
        },
        component: () => import(/* webpackChunkName: "regyjunionai-" */ '@/views/back/aiworkbook/publist/IndexView')
      },
      {
        path: 'pushlist',
        meta: {
          authorities: 'login',
          title: '推送台账',
          keys: ['regyjunionai'],
          activeMenu: '/regyjunionai'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "regyjunionai-pushlist" */ '@/views/back/aiworkbook/pushlist/IndexView')
      }
    ]
  },
  {
    path: '/framework-contractmgr',
    component: BackLayout,
    meta: {
      authorities: 'login',
      title: '我的合同'
      // keys: ['contractmgr']
    },
    hidden: true,
    children: [
      {
        path: '',
        name: 'HZDJ_FRAMEWORK_CONTRACT_TABLE',
        meta: {
          authorities: 'login',
          icon: 'icon-baoxianhetong',
          title: '我的合同',
          activeMenu: '/memberindex'
          // keys: ['contractmgr']
        },
        component: () => import(/* webpackChunkName: "framework-contractmgr" */ '@/views/back/contract/framework/hzdj/IndexView')
      }
    ]
  }
]
export default HZDJ

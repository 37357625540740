import CryptoJS from 'crypto-js'

const TokenKey = 'Mmt-Token'
const ErrorTime = 'Error-Time'
const LOGIN_LASTTIME = 'LOGIN_LASTTIME'
const KEY = '04FCF3202FF5FA9A487501885FC25A15EA5BF6B3A23133F458CA3FFD6B89'

function aesEncrypt (data = {}) {
  const code = CryptoJS.MD5(KEY).toString()
  const iv = CryptoJS.enc.Utf8.parse(code.substring(0, 16))
  const key = CryptoJS.enc.Utf8.parse(code.substring(16))
  return CryptoJS.AES.encrypt(JSON.stringify(data), key, { mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7, iv: iv }).toString()
}

function aesDecrypt (string) {
  const code = CryptoJS.MD5(KEY).toString()
  const iv = CryptoJS.enc.Utf8.parse(code.substring(0, 16))
  const key = CryptoJS.enc.Utf8.parse(code.substring(16))
  try {
    const bytes = CryptoJS.AES.decrypt(string, key, { mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7, iv: iv })
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8)
    return decryptedData
  } catch (error) {
    console.log(error)
  }
}

function getUserInfo () {
  try {
    const userInfo = window.localStorage[TokenKey] || ''
    return JSON.parse(aesDecrypt(userInfo) || '{}')
  } catch (error) {
    console.log(error)
    window.localStorage[TokenKey] = ''
  }
}

function setUserInfo (data = {}) {
  const ciphertext = aesEncrypt(data)
  window.localStorage[TokenKey] = ciphertext
}

function removeUserInfo () {
  window.localStorage.removeItem(TokenKey)
}

function setErrorTime (data = {}) {
  const ciphertext = aesEncrypt(data)
  window.localStorage['Error-Time'] = ciphertext
}

function getErrorTime () {
  const time = window.localStorage[ErrorTime] || ''
  return JSON.parse(aesDecrypt(time) || '{}')
}

function removeErrorTime () {
  window.localStorage.removeItem(ErrorTime)
}

function getLastTime () {
  const time = window.localStorage[LOGIN_LASTTIME] || ''
  return parseInt(aesDecrypt(time) || 0)
}

function setLastTime (time) {
  const ciphertext = aesEncrypt(time)
  window.localStorage[LOGIN_LASTTIME] = ciphertext
}
export { KEY, getUserInfo, setUserInfo, removeUserInfo, aesEncrypt, aesDecrypt, setErrorTime, getErrorTime, removeErrorTime, getLastTime, setLastTime }

<template>
  <el-container>
    <el-header class="hd">
      <BackHeader />
    </el-header>
    <el-container class="main_container">
      <!-- <BackMenu /> -->
      <NewBackMenu />
      <el-main>
        <el-scrollbar>
          <div style="padding: 8px">
            <!-- <TagsView /> -->
            <div class="app_main">
              <transition name="fade-transform" mode="out-in">
                <keep-alive :include="cachedViews">
                  <router-view v-if="isReload" />
                </keep-alive>
              </transition>
            </div>
          </div>
        </el-scrollbar>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import BackHeader from '@/components/BackHeader'
// import BackMenu from '@/components/BackMenu'
import NewBackMenu from '@/components/NewBackMenu'
// import TagsView from '@/components/TagsView'
import { mapState } from 'vuex'
export default {
  provide () {
    return {
      reload: this.reload
    }
  },
  components: {
    BackHeader,
    // BackMenu,
    NewBackMenu
    // TagsView
  },
  data () {
    return {
      isReload: true
    }
  },
  computed: {
    ...mapState({
      cachedViews: state => state.tagsView.cachedViews
    })
  },
  created () {},
  methods: {
    reload () {
      this.isReload = false
      this.$nextTick(() => {
        this.isReload = true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.hd {
  padding: 0;
  // height: 44px !important;
  height: 40px !important;
}
.sider_container {
  width: 210px !important;
  overflow: hidden;
  background-color: #fff;
  border-right: solid 1px #e6e6e6;

  .el-scrollbar {
    height: 100%;
  }

  ::v-deep.el-scrollbar__wrap {
    overflow-x: hidden;
  }
}
.main_container {
  height: calc(100vh - 44px);
  // height: 100vh;
  background: #f7f8fa;

  .el-main {
    overflow: hidden;
    padding: 0;

    .el-scrollbar {
      height: 100%;
    }

    ::v-deep.el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }

  // .app_main {
  //   // padding-top: 20px;
  // }
}

.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all 0.5s;
}

.fade-transform-enter {
  opacity: 0;
  transform: translateX(-30px);
}

.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>

import BackLayout from '@/views/back/LayoutView'
const JJXT = [
  {
    path: '/memberindex',
    component: BackLayout,
    meta: {
      keys: ['memberindex']
    },
    children: [
      {
        path: '',
        meta: {
          authorities: 'login',
          title: '后台首页',
          icon: 'icon-home'
        },
        component: () => import(/* webpackChunkName: "memberindex" */ '@/views/back/dashboard/jjxt/IndexView')
      }
    ]
  },
  {
    path: '/viraccountset',
    component: BackLayout,
    meta: {
      title: '个人账户设置',
      icon: 'icon-Union-5',
      keys: ['viraccountset']
    },
    children: [
      {
        path: 'accbaseinfo',
        meta: {
          authorities: 'login',
          title: '个人基本信息',
          keys: ['accbaseinfo']
        },
        component: () => import(/* webpackChunkName: "viraccountset-accbaseinfo" */ '@/views/person/basexx/IndexView')
      },
      {
        path: 'cardxx',
        meta: {
          title: '身份基本信息',
          activeMenu: '/viraccountset/accbaseinfo',
          keys: ['accbaseinfo'],
          authorities: 'login'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "viraccountset-cardxx" */ '@/views/person/basexx/IDCardView')
      },
      {
        path: 'mobmgr',
        meta: {
          title: '修改手机号',
          activeMenu: '/viraccountset/accbaseinfo',
          keys: ['accbaseinfo'],
          authorities: 'login'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "viraccountset-mobmgr" */ '@/views/person/basexx/phone/IndexView')
      },
      {
        path: 'person',
        meta: {
          title: '实名认证',
          activeMenu: '/viraccountset/accbaseinfo',
          keys: ['accbaseinfo'],
          authorities: 'login'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "viraccountset-person" */ '@/views/person/certification/person/IndexView')
      },
      {
        path: 'modpassword',
        meta: {
          authorities: 'login',
          title: '修改密码',
          keys: ['modpassword']
        },
        component: () => import(/* webpackChunkName: "viraccountset-modpassword" */ '@/views/person/password/IndexView')
      }
    ]
  },
  {
    path: '/myorders',
    component: BackLayout,
    meta: {
      keys: ['myorders'],
      title: '订单管理中心'
    },
    children: [
      {
        path: '',
        name: 'JjxtMyOrderMgr',
        meta: {
          authorities: 'login',
          title: '订单管理中心',
          breadcrumb: false,
          keys: ['myorders'],
          icon: 'icon-Union-2'
        },
        component: () => import(/* webpackChunkName: "myorders" */ '@/views/back/myorders/jjxt/list/IndexView')
      },
      {
        path: 'detail',
        meta: {
          authorities: 'login',
          title: '查看详情',
          keys: ['myorders'],
          activeMenu: '/myorders'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "myorders-detail" */ '@/views/back/myorders/detail/IndexView')
      },
      {
        path: 'sendcar',
        meta: {
          authorities: 'login',
          title: '我要派单',
          keys: ['myorders'],
          activeMenu: '/myorders'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "myorders-sendcar" */ '@/views/back/myorders/jjxt/sendcar/IndexView')
      },
      {
        path: 'resendcar',
        meta: {
          authorities: 'login',
          title: '我要改派',
          keys: ['myorders'],
          activeMenu: '/myorders'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "myorders-resendcar" */ '@/views/back/myorders/jjxt/resendcar/IndexView')
      },
      {
        path: 'addcar',
        meta: {
          authorities: 'login',
          title: '添加车辆',
          keys: ['myorders'],
          activeMenu: '/myorders'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "myorders-addcar" */ '@/views/back/myorders/wlsj/addcar/IndexView')
      }
    ]
  },
  {
    path: '/virtruckmgr',
    component: BackLayout,
    meta: {
      title: '车辆管理中心',
      icon: 'icon-cheliangguanli',
      keys: ['virtruckmgr']
    },
    children: [
      // {
      //   path: 'tklimit',
      //   meta: {
      //     authorities: 'login',
      //     title: '车辆限制',
      //     keys: ['tklimit']
      //   }
      // },
      {
        path: 'realpos',
        meta: {
          authorities: 'login',
          title: '最新位置查询',
          keys: ['realpos']
        },
        component: () => import(/* webpackChunkName: "realpos" */ '@/views/back/realpos/IndexView')
      },
      {
        path: 'histrack',
        meta: {
          authorities: 'login',
          title: '历史轨迹查询',
          keys: ['histrack']
        },
        component: () => import(/* webpackChunkName: "histrack" */ '@/views/back/histrack/IndexView')
      }
    ]
  },
  {
    path: '/collect',
    component: BackLayout,
    meta: {
      title: '收藏管理中心'
    },
    hidden: true,
    children: [
      {
        path: 'tklist',
        meta: {
          authorities: 'login',
          title: '车源收藏',
          activeMenu: '/memberindex'
        },
        component: () => import(/* webpackChunkName: "collect-tklist" */ '@/views/back/collect/tklist/IndexView')
      },
      {
        path: 'gdlist',
        meta: {
          authorities: 'login',
          title: '货源收藏',
          activeMenu: '/memberindex'
        },
        component: () => import(/* webpackChunkName: "collect-gdlist" */ '@/views/back/collect/gdlist/IndexView')
      }
    ]
  },
  {
    path: '/virsysmgr',
    component: BackLayout,
    meta: {
      title: '系统管理中心',
      icon: 'icon-Union',
      keys: ['virsysmgr']
    },
    alwaysShow: true,
    children: [
      {
        path: 'pslinemgr',
        meta: {
          title: '配送线路管理',
          keys: ['pslinemgr']
        },
        component: () => import(/* webpackChunkName: "pslinemgr" */ '@/views/back/psline/jjxt/IndexView')
      },
      {
        path: 'addpsline',
        meta: {
          title: '新增路线',
          activeMenu: '/virsysmgr/pslinemgr',
          keys: ['pslinemgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "addpsline" */ '@/views/back/psline/jjxt/AddLine')
      },
      {
        path: 'newmgr',
        meta: {
          authorities: 'login',
          title: '资讯管理',
          keys: ['newmgr']
        },
        component: () => import(/* webpackChunkName: "virsysmgr-newmgr" */ '@/views/back/news/list/IndexView')
      },
      {
        path: 'pubnews',
        meta: {
          title: '发布资讯',
          authorities: 'login',
          keys: ['newmgr'],
          activeMenu: '/virsysmgr/newmgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "pubnews" */ '@/views/back/news/publish/IndexView')
      },
      {
        path: 'editnews',
        meta: {
          title: '编辑资讯',
          authorities: 'login',
          keys: ['newmgr'],
          activeMenu: '/virsysmgr/newmgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "editnews" */ '@/views/back/news/detail/IndexView')
      }
    ]
  }
]
export default JJXT

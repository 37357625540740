import Vue from 'vue'
import App from './App.vue'
import DragTable from '@/components/DragTable/DragTable'
import router from './router'
import '@/router/permission'
import './plugins/element.js'
import store from './store'
import '@/styles/app.scss'
import AgTable from '@/components/AgTable/AgTable'
import { createVersionPolling } from 'version-polling/dist/version-polling.esm'
import './registerServiceWorker'

Vue.config.productionTip = false
window.childrenApiUrl = process.env.VUE_APP_BASE_API
Vue.component('DragTable', DragTable)
Vue.component('AgTable', AgTable)

createVersionPolling({
  appETagKey: '__APP_ETAG__',
  pollingInterval: 60 * 1000,
  silent: process.env.NODE_ENV === 'development',
  onUpdate: (self) => {
    alert('有新版本，请刷新页面')
    self.onRefresh()
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// asyncRouter.js
// 判断当前角色是否有访问权限
function hasPermission (keys, route) {
  if (route.meta && route.meta.keys) {
    return keys.some(key => route.meta.keys.includes(key))
  } else {
    return true
  }
}
// 递归过滤异步路由表，筛选角色权限路由
export function filterAsyncRoutes (routes, keys) {
  const res = []
  routes.forEach(route => {
    const tmp = { ...route }
    if (hasPermission(keys, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, keys)
      }
      res.push(tmp)
    }
  })

  return res
}

<template>
  <div>
    <el-dialog
      title="列表设置"
      :visible.sync="isShow"
      class="custome_diaplay_dialog"
      append-to-body
      top="5vh"
      width="624px"
    >
      <div>
        <el-transfer
          filterable
          v-model="checkedColumn"
          :data="data"
          :titles="['隐藏列', '显示列']"
          :props="{
            key: 'colId',
            label: 'headerName',
          }"
        ></el-transfer>
      </div>
      <div slot="footer">
        <el-button size="small" @click="cancel">显示所有列</el-button>
        <el-button type="primary" size="small" @click="handleSubmit"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    data: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  computed: {
    isShow: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  data () {
    return {
      checkedColumn: []
    }
  },
  created () {
    this.checkedColumn = this.data.filter(item => item.visible).map(v => v.colId)
  },
  methods: {
    cancel () {
      const showColumn = this.data.map(item => {
        return {
          colId: item.colId,
          hide: false
        }
      })
      this.$emit('display-change', showColumn)
      this.isShow = false
    },
    handleSubmit () {
      const hideColumn = this.data.filter(item => !this.checkedColumn.includes(item.colId)).map(item => {
        return {
          colId: item.colId,
          hide: true
        }
      })
      const showColumn = this.checkedColumn.map(item => {
        return {
          colId: item,
          hide: false
        }
      })
      this.$emit('display-change', [...hideColumn, ...showColumn])
      this.isShow = false
    }
  }

}
</script>

<style lang="scss" scoped>
.custome_diaplay_dialog {
  ::v-deep .el-dialog__body {
    padding-top: 10px;
  }

  ::v-deep .el-transfer-panel__body {
    height: calc(100vh - 400px);
  }

  ::v-deep .el-transfer-panel__list.is-filterable {
    height: calc(100vh - 452px);
  }
}
</style>

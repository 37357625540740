import Vue from 'vue'
import {
  Button, Form, FormItem, Input, Header, Switch, Avatar, Dropdown, DropdownMenu,
  DropdownItem, Aside, Container, Main, MenuItem, Submenu, MenuItemGroup, Menu,
  Divider, Carousel, CarouselItem, Table, TableColumn, Link, Tag, Image, Card,
  Pagination, Backtop, Icon, Breadcrumb, BreadcrumbItem, TabPane, Tabs, Rate,
  Upload, DatePicker, Dialog, Descriptions, DescriptionsItem, Select, Option,
  Cascader, CascaderPanel, Scrollbar, Message, Loading, Timeline, TimelineItem,
  MessageBox, Empty, Popover, Radio, RadioGroup, Steps, Step, Result, Col, Row,
  TimePicker, Checkbox, CheckboxGroup, ButtonGroup, RadioButton, Transfer, InputNumber, Drawer,
  Statistic, Tooltip, Badge, Progress, Notification, Alert
} from 'element-ui'
import '@/styles/element-variables.scss'

Vue.use(Alert)
Vue.use(Badge)
Vue.use(Tooltip)
Vue.use(Statistic)
Vue.use(Drawer)
Vue.use(Icon)
Vue.use(Avatar)
Vue.use(Button)
Vue.use(ButtonGroup)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Container)
Vue.use(Main)
Vue.use(Menu)
Vue.use(MenuItemGroup)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(Divider)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Link)
Vue.use(Tag)
Vue.use(Image)
Vue.use(Pagination)
Vue.use(Backtop)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Switch)
Vue.use(Rate)
Vue.use(Upload)
Vue.use(DatePicker)
Vue.use(Dialog)
Vue.use(Descriptions)
Vue.use(DescriptionsItem)
Vue.use(Select)
Vue.use(Option)
Vue.use(Cascader)
Vue.use(CascaderPanel)
Vue.use(Scrollbar)
Vue.use(Loading)
Vue.use(Timeline)
Vue.use(TimelineItem)
Vue.use(Card)
Vue.use(Empty)
Vue.use(Popover)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(RadioButton)
Vue.use(Steps)
Vue.use(Step)
Vue.use(Result)
Vue.use(Row)
Vue.use(Col)
Vue.use(TimePicker)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Transfer)
Vue.use(Progress)
Vue.prototype.$message = Message
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$notify = Notification

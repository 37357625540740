const user = [{
  path: '/user',
  redirect: '/user/login',
  component: () => import(/* webpackChunkName: "user" */ '@/views/user/LayoutView'),
  children: [
    {
      path: 'login',
      component: () => import(/* webpackChunkName: "login" */ '@/views/user/LoginView')
    },
    {
      path: 'tellogin',
      component: () => import(/* webpackChunkName: "tellogin" */ '@/views/user/TelLoginView')
    },
    {
      path: 'register',
      component: () => import(/* webpackChunkName: "register" */ '@/views/user/RegisterView')
    },
    {
      path: 'reset',
      component: () => import(/* webpackChunkName: "reset" */ '@/views/user/ResetView')
    }
  ]
}]
export default user

import path from 'path'
/**
 * @description 创建菜单
 * @param {Function} h createElement
 * @param {Object} menu 菜单项
 */
export function elMenuItem (h, menu, basePath) {
  let icon = null
  if (menu.meta && menu.meta.icon) icon = <i class={ `iconfont ${menu.meta.icon}` }/>
  return <el-menu-item
    key={ resolvePath(basePath, menu.path) }
    index={ resolvePath(basePath, menu.path) }>
    { icon }
    <span slot="title">{ (menu.meta && menu.meta.title) || '未命名菜单' }</span>
  </el-menu-item>
}

/**
 * @description 创建子菜单
 * @param {Function} h createElement
 * @param {Object} menu 菜单项
 */
export function elSubmenu (h, menu, basePath) {
  let icon = null
  if (menu.meta && menu.meta.icon) icon = <i slot="title" class={ `iconfont ${menu.meta.icon}` }/>
  return <el-submenu
    key={ resolvePath(basePath, menu.path) }
    index={ resolvePath(basePath, menu.path) }>
    { icon }
    <span slot="title">{ (menu.meta && menu.meta.title) || '未命名菜单' }</span>
    { menu.children.map(child => createMenu.call(this, h, child, resolvePath(basePath, menu.path))) }
  </el-submenu>
}

const resolvePath = (basePath, routePath) => {
  return path.resolve(basePath, routePath)
}

/**
 * @description 在组件中调用此方法渲染菜单项目
 * @param {Function} h createElement
 * @param {Object} menu 菜单项
 */
export function createMenu (h, menu, basePath) {
  if (!menu.hidden) {
    const hasOneShowingChild = (children = [], parent) => {
      const showingChildren = children.filter(item => !item.hidden)
      return showingChildren.length === 1
    }
    if (menu.children === undefined) return elMenuItem.call(this, h, menu, basePath)
    else if (hasOneShowingChild(menu.children, menu) && !menu.alwaysShow) return elMenuItem.call(this, h, menu.children[0], basePath)
    return elSubmenu.call(this, h, menu, basePath)
  }
}

import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store/index'
import router from '@/router/index'
import rsaEncrypt from '@/untils/rsa-encrypt'
import { aesEncrypt } from '@/untils/storage'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  headers: {
    'Content-Type': 'text/plain'
  }
})

service.interceptors.request.use(
  config => {
    const userInfo = store.getters.userInfo
    if (config.method === 'post') {
      const { data = {} } = config
      const newDate = new Date()
      const content = 'bochang' + ':' + (newDate.getFullYear() + '-' + ((newDate.getMonth() + 1) < 10 ? ('0' + (newDate.getMonth() + 1)) : (newDate.getMonth() + 1)) + '-' + (newDate.getDate() < 10 ? ('0' + newDate.getDate()) : newDate.getDate()))
      data.username = data.username || userInfo.username
      data.actionname !== 'getCompList4Change' && process.env.NODE_ENV === 'development' && console.log({ ...data })
      data.key = rsaEncrypt(content)
      data.session_id = data.session_id || userInfo.session_id
      // if (process.env.NODE_ENV === 'production') config.data = aesEncrypt(data)
      // else config.data = JSON.stringify(data)

      config.data = aesEncrypt(data)
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    const { data, status } = response
    if (status === 200) {
      if (data.status === 0) {
        let errMessage = data.info || ''
        errMessage = typeof errMessage === 'string' ? errMessage : (errMessage.msg || JSON.stringify(errMessage))
        if (errMessage === '101' || errMessage.includes('未登录')) {
          store.dispatch('logout')
          Message.error('未登录或登录状态已过期！')
          const { fullPath } = router.currentRoute
          router.push({
            path: '/user/login',
            query: {
              redirect: fullPath
            }
          })
        } else {
          Message.error(errMessage)
          return data
        }
      } else {
        if (Object.prototype.toString.call(data) !== '[object Object]') Message.error('接口返回参数错误！')
        return data
      }
    }
    return response
  },
  error => {
    Message.error('网络请求失败！')
    return Promise.reject(error)
  }
)

export const fileService = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  headers: {
    'Content-type': 'multipart/form-data'
  }
})

fileService.interceptors.request.use(
  config => {
    const userInfo = store.getters.userInfo
    if (config.method === 'post') {
      // console.log(process.env.NODE_ENV === 'production')
      const { data: formData } = config
      const newDate = new Date()
      const content = 'bochang' + ':' + (newDate.getFullYear() + '-' + ((newDate.getMonth() + 1) < 10 ? ('0' + (newDate.getMonth() + 1)) : (newDate.getMonth() + 1)) + '-' + (newDate.getDate() < 10 ? ('0' + newDate.getDate()) : newDate.getDate()))
      formData.append('key', rsaEncrypt(content))
      formData.append('session_id', userInfo.session_id)
      formData.append('username', userInfo.username)
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

fileService.interceptors.response.use(
  response => {
    const { data, status } = response
    if (status === 200) {
      if (data.status === 0 && data.info === '101') {
        store.dispatch('logout')
        Message.error('未登录或登录状态已过期！')
        const { fullPath } = router.currentRoute
        router.push({
          path: '/user/login',
          query: {
            redirect: fullPath
          }
        })
      } else {
        return data
      }
    }
    return response
  },
  error => {
    Message.error('网络请求失败！')
    return Promise.reject(error)
  }
)

export default service

import BackLayout from '@/views/back/LayoutView'
const SJZJ = [
  {
    path: '/memberindex',
    component: BackLayout,
    meta: {
      keys: ['memberindex']
    },
    children: [
      {
        path: '',
        meta: {
          authorities: 'login',
          title: '后台首页',
          icon: 'icon-home',
          keys: ['memberindex']
        },
        component: () => import(/* webpackChunkName: "memberindex" */ '@/views/back/dashboard/sjzj/IndexView')
      }
    ]
  },
  {
    path: '/viraccountset',
    redirect: '/viraccountset/accbaseinfo',
    component: BackLayout,
    meta: {
      title: '个人账户设置',
      icon: 'icon-Union-5',
      keys: ['viraccountset']
    },
    alwaysShow: true,
    children: [
      {
        path: 'accbaseinfo',
        meta: {
          authorities: 'login',
          title: '个人基本信息',
          keys: ['accbaseinfo']
        },
        component: () => import(/* webpackChunkName: "viraccountset-accbaseinfo" */ '@/views/person/basexx/IndexView')
      },
      {
        path: 'bzlicense',
        meta: {
          authorities: 'login',
          title: '营业执照',
          keys: ['accbaseinfo']
        },
        component: () => import(/* webpackChunkName: "viraccountset-bzlicense" */ '@/views/person/certification/sjzj/bzlicense/IndexView')
      },
      {
        path: 'cardxx',
        meta: {
          title: '身份基本信息',
          activeMenu: '/viraccountset/accbaseinfo',
          keys: ['accbaseinfo'],
          authorities: 'login'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "viraccountset-cardxx" */ '@/views/person/basexx/IDCardView')
      },
      {
        path: 'mobmgr',
        meta: {
          title: '修改手机号',
          activeMenu: '/viraccountset/accbaseinfo',
          keys: ['accbaseinfo'],
          authorities: 'login'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "viraccountset-mobmgr" */ '@/views/person/basexx/phone/IndexView')
      },
      {
        path: 'myrzmgr',
        meta: {
          authorities: 'login',
          title: '我的认证',
          keys: ['myrzmgr']
        },
        component: () => import(/* webpackChunkName: "viraccountset-myrzmgr" */ '@/views/person/certification/ApplyView')
      },
      {
        path: 'person',
        meta: {
          title: '实名认证',
          activeMenu: '/viraccountset/myrzmgr',
          keys: ['myrzmgr'],
          authorities: 'login'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "viraccountset-person" */ '@/views/person/certification/person/IndexView')
      },
      {
        path: 'driverchk',
        meta: {
          title: '司机之家认证',
          authorities: 'login',
          activeMenu: '/viraccountset/myrzmgr',
          keys: ['myrzmgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "viraccountset-drivercheck" */ '@/views/person/certification/sjzj/ChkView')
      },
      {
        path: 'driverunchk',
        meta: {
          title: '司机之家认证',
          authorities: 'login',
          activeMenu: '/viraccountset/myrzmgr',
          keys: ['myrzmgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "viraccountset-driverunchk" */ '@/views/person/certification/sjzj/UnChkView')
      },
      {
        path: 'driver',
        meta: {
          title: '司机之家认证',
          authorities: 'login',
          activeMenu: '/viraccountset/myrzmgr',
          keys: ['myrzmgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "viraccountset-driver" */ '@/views/person/certification/sjzj/IndexView')
      },
      {
        path: 'mmtwallet',
        meta: {
          authorities: 'login',
          title: '默默账簿',
          keys: ['mmtwallet']
        },
        component: () => import(/* webpackChunkName: "viraccountset-mmtwallet" */ '@/views/person/wallet/sjzj/IndexView')
      },
      {
        path: 'creditstar',
        meta: {
          authorities: 'login',
          title: '信用星级',
          keys: ['creditstar']
        },
        component: () => import(/* webpackChunkName: "viraccountset-creditstar" */ '@/views/person/creditstar/sjzj/IndexView')
      },
      {
        path: 'modpassword',
        meta: {
          authorities: 'login',
          title: '修改密码',
          keys: ['modpassword']
        },
        component: () => import(/* webpackChunkName: "viraccountset-modpassword" */ '@/views/person/password/IndexView')
      }
    ]
  },
  {
    path: '/psordermgr',
    component: BackLayout,
    meta: {
      keys: ['psordermgr'],
      title: '订单管理中心'
    },
    children: [
      {
        path: '',
        meta: {
          authorities: 'login',
          title: '订单管理中心',
          icon: 'icon-Union-2',
          breadcrumb: false,
          keys: ['psordermgr']
        },
        component: () => import(/* webpackChunkName: "psordermgr" */ '@/views/back/psorder/sjzj/list/IndexView')
      },
      {
        path: 'detail',
        meta: {
          authorities: 'login',
          title: '查看详情',
          keys: ['psordermgr'],
          activeMenu: '/psordermgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "psordermgr-detail" */ '@/views/back/psorder/sjzj/detail/IndexView')
      },
      {
        path: 'agree',
        meta: {
          authorities: 'login',
          title: '货物运输协议',
          keys: ['psordermgr'],
          activeMenu: '/psordermgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "psordermgr-agree" */ '@/views/back/psorder/sjzj/agree/IndexView')
      },
      {
        path: 'agreedetail',
        meta: {
          authorities: 'login',
          title: '货物运输协议',
          keys: ['psordermgr'],
          activeMenu: '/psordermgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "psordermgr-agreedetail" */ '@/views/back/psorder/yyplat/agree/IndexView')
      },
      {
        path: 'result',
        meta: {
          authorities: 'login',
          title: '货物运输协议',
          keys: ['psordermgr'],
          activeMenu: '/psordermgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "psordermgr-result" */ '@/views/back/psorder/sjzj/result/IndexView')
      }
    ]
  },
  {
    path: '/ordersignmgr',
    component: BackLayout,
    meta: {
      keys: ['ordersignmgr'],
      title: '签收管理中心'
    },
    children: [
      {
        path: '',
        meta: {
          authorities: 'login',
          title: '签收管理中心',
          icon: 'icon-jiesuanguanli',
          breadcrumb: false,
          keys: ['ordersignmgr']
        },
        component: () => import(/* webpackChunkName: "ordersignmgr" */ '@/views/back/ordersign/sjzj/IndexView')
      },
      {
        path: 'detail',
        meta: {
          authorities: 'login',
          title: '查看详情',
          keys: ['ordersignmgr'],
          activeMenu: '/ordersignmgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "ordersigndetail" */ '@/views/back/ordersign/sjzj/detail/IndexView')
      }
    ]
  },
  {
    path: '/truckmgr',
    component: BackLayout,
    meta: {
      keys: ['truckmgr'],
      title: '车辆管理中心'
    },
    children: [
      {
        path: '',
        meta: {
          authorities: 'login',
          title: '车辆管理中心',
          breadcrumb: false,
          icon: 'icon-cheliangguanli',
          keys: ['truckmgr']
        },
        component: () => import(/* webpackChunkName: "truckmgr" */ '@/views/back/truck/sjzj/list/IndexView')
      },
      {
        path: 'edit/:regtkid',
        meta: {
          authorities: 'login',
          title: '编辑车辆',
          activeMenu: '/truckmgr',
          keys: ['truckmgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "truckmgr-edit" */ '@/views/back/truck/sjzj/edit/IndexView')
      },
      {
        path: 'retj/:tkappid',
        meta: {
          authorities: 'login',
          title: '重新提交',
          activeMenu: '/truckmgr',
          keys: ['truckmgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "truckmgr-edit" */ '@/views/back/truck/sjzj/edit/IndexView')
      },
      {
        path: 'detail',
        redirect: '/truckmgr',
        meta: {
          authorities: 'login',
          activeMenu: '/truckmgr',
          keys: ['truckmgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "truckmgr-detail" */ '@/views/back/truck/detail/IndexView'),
        children: [
          {
            path: 'base',
            meta: {
              authorities: 'login',
              title: '基本信息',
              activeMenu: '/truckmgr',
              keys: ['truckmgr']
            },
            component: () => import(/* webpackChunkName: "truckmgr-detail-base" */ '@/views/back/truck/detail/base/IndexView')
          },
          {
            path: 'vehlic',
            meta: {
              authorities: 'login',
              title: '行驶证',
              activeMenu: '/truckmgr',
              keys: ['truckmgr']
            },
            component: () => import(/* webpackChunkName: "truckmgr-detail-vehlic" */ '@/views/back/truck/detail/vehlic/IndexView')
          },
          {
            path: 'roadlic',
            meta: {
              authorities: 'login',
              title: '道路运输证',
              activeMenu: '/truckmgr',
              keys: ['truckmgr']
            },
            component: () => import(/* webpackChunkName: "truckmgr-detail-roadlic" */ '@/views/back/truck/detail/roadlic/IndexView')
          },
          {
            path: 'jyxkz',
            meta: {
              authorities: 'login',
              title: '道路运输经营许可证',
              activeMenu: '/virtruckmgr/truckmgr',
              keys: ['truckmgr']
            },
            component: () => import(/* webpackChunkName: "truckmgr-detail-jyxkz" */ '@/views/back/truck/detail/jyxkz/IndexView')
          }
        ]
      },
      {
        path: 'add',
        meta: {
          authorities: 'login',
          title: '新增车辆',
          activeMenu: '/truckmgr',
          keys: ['truckmgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "truckmgr-edit" */ '@/views/back/truck/sjzj/edit/IndexView')
      }
    ]
  },
  {
    path: '/collect',
    component: BackLayout,
    meta: {
      title: '收藏管理中心'
    },
    hidden: true,
    children: [
      {
        path: 'tklist',
        meta: {
          authorities: 'login',
          title: '车源收藏',
          activeMenu: '/memberindex'
        },
        component: () => import(/* webpackChunkName: "collect-tklist" */ '@/views/back/collect/tklist/IndexView')
      },
      {
        path: 'gdlist',
        meta: {
          authorities: 'login',
          title: '货源收藏',
          activeMenu: '/memberindex'
        },
        component: () => import(/* webpackChunkName: "collect-gdlist" */ '@/views/back/collect/gdlist/IndexView')
      }
    ]
  },
  {
    path: '/tksrcmgr',
    component: BackLayout,
    meta: {
      keys: ['tksrcmgr'],
      title: '车源管理中心'
    },
    children: [
      {
        path: '',
        meta: {
          authorities: 'login',
          title: '车源管理中心',
          breadcrumb: false,
          icon: 'icon-cheyuanguanli',
          keys: ['tksrcmgr']
        },
        component: () => import(/* webpackChunkName: "tksrcmgr" */ '@/views/back/tksrc/list/IndexView')
      },
      {
        path: 'add',
        meta: {
          authorities: 'login',
          title: '发布车源',
          activeMenu: '/tksrcmgr',
          keys: ['tksrcmgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "tksrcmgr-add" */ '@/views/back/tksrc/edit/IndexView')
      },
      {
        path: 'edit/:tksrcid',
        meta: {
          authorities: 'login',
          title: '编辑车源',
          activeMenu: '/tksrcmgr',
          keys: ['tksrcmgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "tksrcmgr-edit" */ '@/views/back/tksrc/edit/IndexView')
      }
    ]
  },
  {
    path: '/zxtsmgr',
    component: BackLayout,
    meta: {
      keys: ['zxtsmgr'],
      title: '咨询投诉中心'
    },
    children: [
      {
        path: '',
        meta: {
          authorities: 'login',
          title: '咨询投诉中心',
          breadcrumb: false,
          icon: 'icon-zixun1',
          keys: ['zxtsmgr']
        },
        component: () => import(/* webpackChunkName: "zxtsmgr" */ '@/views/back/zxts/list/IndexView')
      },
      {
        path: 'add',
        meta: {
          authorities: 'login',
          title: '发布咨询投诉',
          keys: ['zxtsmgr'],
          activeMenu: '/zxtsmgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "zxtsmgr-add" */ '@/views/back/zxts/IndexView')
      }
    ]
  },
  {
    path: '/framework-contractmgr',
    component: BackLayout,
    meta: {
      authorities: 'login',
      title: '我的合同'
      // keys: ['contractmgr']
    },
    hidden: true,
    children: [
      {
        path: '',
        name: 'SJZJ_FRAMEWORK_CONTRACT_TABLE',
        meta: {
          authorities: 'login',
          icon: 'icon-baoxianhetong',
          title: '我的合同',
          activeMenu: '/memberindex'
          // keys: ['contractmgr']
        },
        component: () => import(/* webpackChunkName: "framework-contractmgr" */ '@/views/back/contract/framework/sjzj/IndexView')
      }
    ]
  }
]
export default SJZJ

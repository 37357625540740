<template>
  <div class="header">
    <div class="lf">
      <router-link to="/" class="logo_wrap" :class="{ collapse: isCollapse }">
        <img src="@/assets/logo.png" alt="" v-if="!isCollapse" />
        <img
          src="@/assets/single-logo.png"
          alt=""
          v-if="isCollapse"
          :class="{ collapse: isCollapse }"
        />
      </router-link>
      <!-- <router-link to="/" class="my_link">默默通首页</router-link>
      <router-link to="/gdlist" class="my_link">货源中心</router-link>
      <router-link to="/tklist" class="my_link">车源中心</router-link> -->
      <div style="width: calc(100vw - 420px)">
        <TagsView />
      </div>
    </div>
    <div class="rg">
      <el-dropdown @command="handlePageCommand">
        <el-button icon="el-icon-menu" circle class="tip-button"></el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item icon="el-icon-refresh" command="refresh"
            >刷新</el-dropdown-item
          >
          <el-dropdown-item icon="el-icon-back" command="left"
            >关闭左侧</el-dropdown-item
          >
          <el-dropdown-item icon="el-icon-right" command="right"
            >关闭右侧</el-dropdown-item
          >
          <el-dropdown-item icon="el-icon-close" command="others"
            >关闭其它</el-dropdown-item
          >
          <el-dropdown-item icon="el-icon-circle-close" command="all"
            >关闭全部</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
      <el-tooltip
        effect="dark"
        :content="isFullscreen ? '退出全屏' : '全屏'"
        placement="bottom"
      >
        <el-button
          icon="el-icon-full-screen"
          @click="fullScreenClick"
          circle
          class="tip-button"
        ></el-button>
      </el-tooltip>
      <el-dropdown
        size="medium"
        @command="handleCommand"
        trigger="click"
        @visible-change="handleDropChange"
      >
        <div class="user_info" @mouseenter="handlegpHoverLeave">
          <!-- <el-avatar
            size="medium"
            :src="userInfo.domain + userInfo.icon"
          ></el-avatar> -->
          <el-image
            :src="userInfo.domain + userInfo.icon"
            fit="cover"
            class="my_avatar"
          ></el-image>
          <el-link :underline="false" class="user_name"
            ><div class="name-txt">{{ userInfo.username }}</div>
            <i
              class="el-icon-arrow-down el-icon--right"
              :class="{ 'is-active': dropStatus }"
            ></i>
          </el-link>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-popover
            placement="left"
            trigger="manual"
            popper-class="gp_poper"
            v-model="visible"
          >
            <div class="gpmenu_list" @mouseleave="handlegpHoverLeave">
              <div
                class="gpitem"
                :class="{
                  active:
                    item.compname === selected.compname &&
                    item.roletitle === selected.roletitle
                }"
                v-for="(item, index) in compList"
                :key="index"
                @click="handlegpChangeClick(item)"
              >
                <div>
                  <span style="font-weight: bold; font-size: 16px">{{
                    item.compname
                  }}</span>
                  <span style="margin-left: 10px">{{ item.roletitle }}</span>
                </div>
                <i
                  class="el-icon-check"
                  style="margin-left: 10px"
                  v-if="
                    item.compname === selected.compname &&
                    item.roletitle === selected.roletitle
                  "
                ></i>
              </div>
            </div>
            <el-dropdown-item
              slot="reference"
              icon="el-icon-arrow-left"
              v-show="compList.length !== 0"
              @mouseenter.native="handlegpHoverEnter"
              >切换公司</el-dropdown-item
            >
          </el-popover>
          <el-dropdown-item
            command="changepwd"
            v-show="userInfo.groupname !== 'MMTPT'"
            icon="el-icon-lock"
            @mouseenter.native="handlegpHoverLeave"
            >修改密码</el-dropdown-item
          >
          <el-dropdown-item
            command="lgout"
            divided
            icon="el-icon-switch-button"
            @mouseenter.native="handlegpHoverLeave"
            >退出登录</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import services from '@/services'
import TagsView from '@/components/TagsView'
import { useFullscreen } from '@vueuse/core'
import { mapState, mapGetters, mapActions } from 'vuex'
let timerId = null
export default {
  inject: ['reload'],
  components: {
    TagsView
  },
  setup () {
    const { toggle, isFullscreen } = useFullscreen(
      document.getElementsByTagName('body')[0]
    )
    const fullScreenClick = () => {
      toggle()
    }
    return {
      isFullscreen,
      fullScreenClick
    }
  },
  computed: {
    ...mapGetters(['sidebar']),
    ...mapState({
      userInfo: state => state.user.userInfo
    }),
    isCollapse () {
      return !this.sidebar.opened
    }
  },
  data () {
    return {
      gplist: [],
      compList: [],
      selected: '',
      firstSelect: '',
      dropStatus: false,
      visible: false
    }
  },
  created () {
    this.getCompList4Change()
  },
  mounted () {
    this.queryUserStatus()
  },
  methods: {
    ...mapActions(['logout', 'clearRoleXX']),
    loginout () {
      this.$router.replace('/user/login')
      this.logout()
    },
    handlePageCommand (c) {
      if (c === 'refresh') {
        this.reload()
      }
      if (c === 'all') {
        this.closeAllTags()
      }
      if (c === 'others') {
        this.$store.dispatch('tagsView/delOthersViews', this.$route)
      }
      if (c === 'left') {
        this.$store.dispatch('tagsView/delLeftViews', this.$route)
      }
      if (c === 'right') {
        this.$store.dispatch('tagsView/delRightViews', this.$route)
      }
    },
    closeAllTags () {
      this.$store.dispatch('tagsView/delAllViews')
      this.$router.push('/').catch(() => {})
    },
    handleCommand (command) {
      switch (command) {
        case 'lgout':
          this.loginout()
          break
        case 'changepwd':
          this.$router.push('/viraccountset/modpassword').catch(() => {})
          break
      }
    },
    queryUserStatus () {
      timerId = setTimeout(async () => {
        if (
          this.compList &&
          this.compList.length > 1 &&
          this.userInfo.username
        ) {
          if (document.visibilityState === 'visible') {
            const { info, status } = await services.user.getCompList4Change()
            if (status) {
              const currentcompxx = info.currentcompxx || {}
              if (
                currentcompxx.compname &&
                (currentcompxx.compname !== this.firstSelect.compname ||
                  currentcompxx.roletitle !== this.firstSelect.roletitle)
              ) {
                alert(
                  `检测到账户切换为【${currentcompxx.compname}-${currentcompxx.roletitle}】，请刷新页面`
                )
                window.location.reload()
              }
            }
          }
          this.queryUserStatus()
        }
      }, 6 * 1000)
    },
    async getCompList4Change () {
      try {
        const { status, info } = await services.user.getCompList4Change()
        if (status) {
          this.gplist = info.gplist || []
          const compList = info.canchgcomplist || []
          const currentcompxx = info.currentcompxx || {}
          if (currentcompxx && currentcompxx.compname) {
            this.selected = currentcompxx
            this.firstSelect = currentcompxx
            compList.unshift(currentcompxx)
          }
          this.compList = compList
        }
      } catch (error) {
        this.$message.error(error)
      }
    },
    handlegpChangeClick (item) {
      this.visible = false
      this.selected = item
      if (
        this.selected.compname === this.firstSelect.compname &&
        this.selected.roletitle === this.firstSelect.roletitle
      ) {
        return
      }
      this.changeTheComp(this.selected)
    },
    async changeTheComp (params) {
      try {
        const loading = this.$loading()
        const { status } = await services.user.changeTheComp(params)
        loading.close()
        if (status) {
          this.$notify({
            title: '切换成功',
            dangerouslyUseHTMLString: true,
            message: `<span style="color: #67C23A">${params.compname}-${params.roletitle}</span>`,
            type: 'success',
            duration: 3000
          })
          // this.gplist.forEach(gp => {
          //   if (gp.gid === curgpid) gp.iscurgroup = 1
          //   else gp.iscurgroup = 0
          // })
          await this.clearRoleXX()
          this.$router.replace('/').catch(() => {})
        }
      } catch (error) {
        this.$message.error(error)
      }
    },
    handlegpHoverEnter () {
      this.visible = true
    },
    handlegpHoverLeave () {
      this.visible = false
    },
    handleDropChange (val) {
      this.dropStatus = val
    }
  },
  beforeDestroy () {
    clearTimeout(timerId)
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/_variables';
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  box-sizing: border-box;

  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;

  .lf {
    display: flex;
    align-items: center;
    flex: 1;

    .logo_wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 190px;
      overflow: hidden;
      transition: all ease-in-out 0.3s;

      &.collapse {
        width: 50px;
      }

      img {
        display: block;
        width: 78.5px;
        height: 25px;

        &.collapse {
          width: 36.5px;
          height: 35px;
        }
      }
    }

    .my_link {
      margin-left: 50px;
      color: #606266;
      font-size: 14px;
      text-decoration: none;
      transition: all 0.3s;

      &:hover {
        color: $--color-primary;
        text-decoration: underline;
      }
    }
  }

  .rg {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    padding-right: 20px;

    .tip-button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      width: 28px;
      height: 28px;
      border: none;
      background: #f5f7fa;

      &:hover {
        background-color: #ebf7f0;
      }
    }

    .user_info {
      display: flex;
      align-items: center;
      cursor: pointer;

      .user_name {
        margin-left: 5px;
        font-size: 12px;

        ::v-deep .el-link--inner {
          display: flex;
          align-items: center;
        }

        .name-txt {
          max-width: 60px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .el-icon-arrow-down {
          transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          &.is-active {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .my_avatar {
    width: 28px;
    height: 28px;
    border-radius: 50%;
  }

  ::v-deep .el-tabs__header {
    margin: 0;
    border: none;

    .el-tabs__nav-wrap {
      margin: 0;
      border: none;

      .el-tabs__nav {
        border: none;

        .el-tabs__item {
          // height: 43px;
          // line-height: 43px;
          font-size: 12px;
          font-weight: normal;
          border: none;

          &.is-active {
            background-color: #f0f0f0;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
@import '@/styles/_variables';
.gp_poper {
  padding: 0 !important;

  .gpmenu_list {
    padding: 6px 0;

    .gpitem {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 30px 0 20px;
      height: 34px;
      line-height: 34px;
      cursor: pointer;

      &:hover {
        background-color: #f5f7fa;
      }

      &.active {
        color: $--color-primary;
      }
    }
  }
}
</style>

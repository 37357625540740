import BackLayout from '@/views/back/LayoutView'
const CCGJ = [
  {
    path: '/memberindex',
    component: BackLayout,
    meta: {
      keys: ['memberindex']
    },
    children: [
      {
        path: '',
        meta: {
          authorities: 'login',
          title: '后台首页',
          icon: 'icon-home',
          keys: ['memberindex']
        },
        component: () => import(/* webpackChunkName: "memberindex" */ '@/views/back/dashboard/ccgj/IndexView')
      }
    ]
  },
  {
    path: '/viraccountset',
    component: BackLayout,
    meta: {
      title: '个人账户设置',
      icon: 'icon-Union-5',
      keys: ['viraccountset']
    },
    alwaysShow: true,
    children: [
      {
        path: 'accbaseinfo',
        meta: {
          authorities: 'login',
          title: '个人基本信息',
          keys: ['accbaseinfo']
        },
        component: () => import(/* webpackChunkName: "viraccountset-accbaseinfo" */ '@/views/person/basexx/IndexView')
      },
      {
        path: 'cardxx',
        meta: {
          title: '身份基本信息',
          activeMenu: '/viraccountset/accbaseinfo',
          keys: ['accbaseinfo'],
          authorities: 'login'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "viraccountset-cardxx" */ '@/views/person/basexx/IDCardView')
      },
      {
        path: 'mobmgr',
        meta: {
          title: '修改手机号',
          activeMenu: '/viraccountset/accbaseinfo',
          keys: ['accbaseinfo'],
          authorities: 'login'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "viraccountset-mobmgr" */ '@/views/person/basexx/phone/IndexView')
      },
      {
        path: 'myrzmgr',
        meta: {
          authorities: 'login',
          title: '我的认证',
          keys: ['myrzmgr']
        },
        component: () => import(/* webpackChunkName: "viraccountset-myrzmgr" */ '@/views/person/certification/ApplyView')
      },
      {
        path: 'person',
        meta: {
          title: '实名认证',
          activeMenu: '/viraccountset/myrzmgr',
          keys: ['myrzmgr'],
          authorities: 'login'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "viraccountset-person" */ '@/views/person/certification/person/IndexView')
      },
      {
        path: 'warehousechk',
        meta: {
          title: '仓储管家认证',
          authorities: 'login',
          activeMenu: '/viraccountset/myrzmgr',
          keys: ['myrzmgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "viraccountset-warehousechk" */ '@/views/person/certification/ccgj/ChkView')
      },
      {
        path: 'warehouseunchk',
        meta: {
          title: '仓储管家认证',
          authorities: 'login',
          activeMenu: '/viraccountset/myrzmgr',
          keys: ['myrzmgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "viraccountset-warehouseunchk" */ '@/views/person/certification/ccgj/UnChkView')
      },
      {
        path: 'warehouse',
        meta: {
          title: '仓储管家认证',
          authorities: 'login',
          activeMenu: '/viraccountset/myrzmgr',
          keys: ['myrzmgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "viraccountset-warehouse" */ '@/views/person/certification/ccgj/IndexView')
      },
      {
        path: 'modpassword',
        meta: {
          authorities: 'login',
          title: '修改密码',
          keys: ['modpassword']
        },
        component: () => import(/* webpackChunkName: "viraccountset-modpassword" */ '@/views/person/password/IndexView')
      }
    ]
  },
  {
    path: '/collect',
    component: BackLayout,
    meta: {
      title: '收藏管理中心'
    },
    hidden: true,
    children: [
      {
        path: 'tklist',
        meta: {
          authorities: 'login',
          title: '车源收藏',
          activeMenu: '/memberindex'
        },
        component: () => import(/* webpackChunkName: "collect-tklist" */ '@/views/back/collect/tklist/IndexView')
      },
      {
        path: 'gdlist',
        meta: {
          authorities: 'login',
          title: '货源收藏',
          activeMenu: '/memberindex'
        },
        component: () => import(/* webpackChunkName: "collect-gdlist" */ '@/views/back/collect/gdlist/IndexView')
      }
    ]
  },
  {
    path: '/virwhmgr',
    component: BackLayout,
    meta: {
      title: '仓库管理中心',
      icon: 'icon-cangkuguanli',
      keys: ['virwhmgr']
    },
    alwaysShow: true,
    children: [
      {
        path: 'whsetmgr',
        meta: {
          authorities: 'login',
          title: '仓库定义',
          keys: ['whsetmgr'],
          iframeSrc: '/plugins/whserv/project1/page/whnameMgr/whnameMgr.html'
        },
        component: () => import(/* webpackChunkName: "whsetmgr" */ '@/views/back/warehouse/iframe/IndexView')
      },
      {
        path: 'matmgr',
        meta: {
          authorities: 'login',
          title: '物料管理',
          keys: ['matmgr'],
          iframeSrc: '/plugins/whserv/project1/page/MaterialMgr/weihuMgr4cc.html'

        },
        component: () => import(/* webpackChunkName: "matmgr" */ '@/views/back/warehouse/ccgj/matmgr/IndexView')
      },
      {
        path: 'matapplymgr',
        meta: {
          authorities: 'login',
          title: '我的添加',
          keys: ['matmgr'],
          activeMenu: '/virsysmgr/matmgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "matapplymgr" */ '@/views/back/warehouse/ccgj/matapplymgr/IndexView')
      },
      {
        path: 'wldwmgr',
        meta: {
          authorities: 'login',
          title: '往来单位管理',
          keys: ['wldwmgr'],
          iframeSrc: '/plugins/whserv/project1/page/wldwMgr/index.html'
        },
        component: () => import(/* webpackChunkName: "wldwmgr" */ '@/views/back/warehouse/iframe/IndexView')
      }
    ]
  },
  {
    path: '/virtkqueuemgr',
    component: BackLayout,
    meta: {
      title: '排队管理中心',
      icon: 'icon-paidui',
      keys: ['virtkqueuemgr']
    },
    alwaysShow: true,
    children: [
      {
        path: 'queue-configure',
        meta: {
          authorities: 'login',
          title: '排队设置',
          keys: ['tksign']
        },
        component: () => import(/* webpackChunkName: "queue-configure" */ '@/views/back/queue/configure/IndexView')
      },
      {
        path: 'tksign',
        meta: {
          authorities: 'login',
          title: '车辆签到',
          keys: ['tksign']
        },
        component: () => import(/* webpackChunkName: "tksign" */ '@/views/back/tksign/IndexView')
      },
      {
        path: 'callmgr',
        meta: {
          authorities: 'login',
          title: '呼叫管理',
          keys: ['callmgr']
        },
        component: () => import(/* webpackChunkName: "callmgr" */ '@/views/back/callmgr/IndexView')
      },
      {
        path: 'realpos',
        meta: {
          authorities: 'login',
          title: '最新位置查询',
          keys: ['realpos']
        },
        component: () => import(/* webpackChunkName: "realpos" */ '@/views/back/realpos/IndexView')
      },
      {
        path: 'histrack',
        meta: {
          authorities: 'login',
          title: '历史轨迹查询',
          keys: ['histrack']
        },
        component: () => import(/* webpackChunkName: "histrack" */ '@/views/back/histrack/IndexView')
      }
    ]
  },
  {
    path: '/virjxcmgr',
    component: BackLayout,
    meta: {
      title: '进销存管理中心',
      icon: 'icon-a-Inventorydumprequest',
      keys: ['virjxcmgr']
    },
    alwaysShow: true,
    children: [
      {
        path: 'rkmgr',
        meta: {
          authorities: 'login',
          title: '入库管理',
          keys: ['rkmgr'],
          iframeSrc: '/plugins/whserv/project2/page/storage-enter-index.html'
        },
        component: () => import(/* webpackChunkName: "matmgr" */ '@/views/back/warehouse/ccgj/entermgr/IndexView')
      },
      {
        path: 'ckmgr',
        meta: {
          authorities: 'login',
          title: '出库管理',
          keys: ['ckmgr'],
          iframeSrc: '/plugins/whserv/project2/page/storage-outer-index.html'
        },
        component: () => import(/* webpackChunkName: "matmgr" */ '@/views/back/warehouse/ccgj/outermgr/IndexView')
      },
      {
        path: 'invenmgr',
        meta: {
          authorities: 'login',
          title: '盘点管理',
          keys: ['invenmgr'],
          iframeSrc: '/plugins/whserv/project2/page/check-storage-index.html'
        },
        component: () => import(/* webpackChunkName: "matmgr" */ '@/views/back/warehouse/iframe/IndexView')
      }
    ]
  },
  {
    path: '/virinvenmgr',
    component: BackLayout,
    meta: {
      title: '库存管理中心',
      icon: 'icon-cangkuguanli',
      keys: ['virinvenmgr']
    },
    alwaysShow: true,
    children: [
      {
        path: 'realinven',
        meta: {
          authorities: 'login',
          title: '实时库存',
          keys: ['realinven'],
          iframeSrc: '/plugins/whserv/project1/page/ssStock4cc/index.html'
        },
        component: () => import(/* webpackChunkName: "realinven" */ '@/views/back/warehouse/iframe/IndexView')
      },
      {
        path: 'invenaccount',
        meta: {
          authorities: 'login',
          title: '库存台帐',
          keys: ['invenaccount'],
          iframeSrc: '/plugins/whserv/project1/page/stockTable4cc/index4wldw.html'
        },
        component: () => import(/* webpackChunkName: "invenaccount" */ '@/views/back/warehouse/iframe/IndexView')
      }
    ]
  },
  {
    path: '/virsysmgr',
    component: BackLayout,
    meta: {
      keys: ['virsysmgr'],
      title: '系统管理中心',
      icon: 'icon-Union'
    },
    alwaysShow: true,
    children: [
      {
        path: 'deptmgr',
        meta: {
          authorities: 'login',
          title: '部门管理',
          keys: ['deptmgr']
        },
        component: () => import(/* webpackChunkName: "virsysmgr-deptmgr" */ '@/views/back/department/IndexView')
      },
      {
        path: 'staffmgr',
        meta: {
          authorities: 'login',
          title: '员工账号管理',
          keys: ['staffmgr']
        },
        component: () => import(/* webpackChunkName: "virsysmgr-staffmgr" */ '@/views/back/compuser/IndexView')
      },
      {
        path: 'flowmgr',
        meta: {
          authorities: 'login',
          title: '流程管理',
          keys: ['flowmgr']
        },
        component: () => import(/* webpackChunkName: "virsysmgr-flowmgr" */ '@/views/back/flow/IndexView')
      },
      {
        path: 'newmgr',
        meta: {
          authorities: 'login',
          title: '资讯管理',
          keys: ['newmgr']
        },
        component: () => import(/* webpackChunkName: "virsysmgr-newmgr" */ '@/views/back/news/list/IndexView')
      }
    ]
  }
]
export default CCGJ

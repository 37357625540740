const getters = {
  userInfo: state => state.user.userInfo,
  lastTime: state => state.user.lastTime,
  errorTime: state => state.user.errorTime,
  role: state => state.user.role,
  permission_routes: state => state.router.routes,
  permission_keys: state => state.router.keys,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  sidebar: state => state.app.sidebar
}
export default getters

import { render, staticRenderFns } from "./LayoutView.vue?vue&type=template&id=815bf750&scoped=true&"
import script from "./LayoutView.vue?vue&type=script&lang=js&"
export * from "./LayoutView.vue?vue&type=script&lang=js&"
import style0 from "./LayoutView.vue?vue&type=style&index=0&id=815bf750&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "815bf750",
  null
  
)

export default component.exports